<template>
    <div class="grid i-mt-22 pr-18px" id="workspace-menu">
        <div @click="canCreate(activeWorkspaceObj.cans) && $emit('createNew')" class="cursor-pointer grid place-content-center border border-primary-four rounded shadow bg-white hover:bg-primary-four text-primary-one hover:text-white text-center gap-1 navigation">
            <IconPlus />
            <span class="fs-14 fw-500">CREATE NEW</span>
        </div>
        <router-link :to="{name: 'workspace.howItWorks'}" class="cursor-pointer grid place-content-center border border-primary-four rounded shadow bg-white hover:bg-primary-four text-primary-one hover:text-white text-center gap-1 navigation">
            <IconMagnify />
            <span class="fs-14 fw-500">HOW IT WORKS</span>
        </router-link>
        <router-link :to="{name: 'workspace.myProjects'}" class="cursor-pointer grid place-content-center border border-primary-four rounded shadow bg-white hover:bg-primary-four text-primary-one hover:text-white text-center gap-1 navigation">
            <IconStack />
            <span class="fs-14 fw-500">MY PROJECTS</span>
        </router-link>
        <router-link :to="{ name: 'workspace.defaultTemplates' }" class="cursor-pointer grid place-content-center border border-primary-four rounded shadow bg-white hover:bg-primary-four text-primary-one hover:text-white text-center gap-1 navigation">
            <IconCopy />
            <span class="fs-14 fw-500">DEFAULT TEMPLATES</span>
        </router-link>
        <router-link :to="{ name: 'workspace.userProfile' }" class="cursor-pointer grid place-content-center border border-primary-four rounded shadow bg-white hover:bg-primary-four text-primary-one hover:text-white text-center gap-1 navigation">
            <IconUser />
            <span class="fs-14 fw-500">MY PROFILE</span>
        </router-link>
        <div class="cursor-pointer grid place-content-center border border-primary-four rounded shadow bg-white hover:bg-primary-four text-primary-one hover:text-white text-center gap-1 navigation">
            <IconChart />
            <span class="fs-14 fw-500">THOUGHT LEADERSHIP</span>
        </div>
    </div>
</template>

<script setup>
    import { mapGetters } from 'vuex';
    import IconPlus from './icons/IconPlus.vue'
    import IconMagnify from './icons/IconMagnify.vue'
    import IconStack from './icons/IconStack.vue'
    import IconCopy from './icons/IconCopy.vue'
    import IconUser from './icons/IconUser.vue'
    import IconChart from './icons/IconChart.vue'
    import store from '@/store'
    import { computed, ref } from 'vue'

    import usePermission from '@/composable/usePermission.js'
    const { canCreate } = usePermission()

    const activeWorkspaceObj = computed(()=>{
        return store.getters['workspace/activeWorkspaceObj']
    })
</script>

<style scoped>
    #workspace-menu{
        gap: 35px;
        width: 100%;
        grid-template-columns: repeat(6, minmax(133px, 1fr));
        overflow-x: auto;
    }
    .navigation{
        height: 112px;
    }
    .shadow {
        box-shadow: 0px 2px 8px rgba(44, 52, 65, 0.1);
    }
    #workspace-menu :is(.router-link-exact-active) {
        background: var(--color-primary-four);
        color: #fff;
    }

/*     @media all and (max-width: 1850px) and (min-width: 1300px){
        #workspace-menu{
            gap: 10px;
        }
        #workspace-menu>*{
            height: auto;
            padding: 25px 15px;
        }
    } */
</style>
