<template>
  <div class="notification-popup absolute">
    <div class="bg-primary-three shadow-one rounded relative">
      <h2 class="text-primary-one fs-18 fw-600 font-poppins i-pt-30 i-pl-30 i-pr-30">Notification</h2>
      <div class="flex justify-between items-center mt-5">
        <router-link :to="{name: 'notification'}">
          <h2 class="text-primary-four fs-14 fw-600 font-poppins i-pl-30 cursor-pointer">See All Notification</h2>
        </router-link>
        <div class="fs-14 font-poppins fw-600 text-primary-four cursor-pointer flex justify-end pr-10 mark-read-btn items-center i-pr-30" @click="markAllAsRead()">
          <img src="/images/icons/notification_check_circle.svg" alt="icon" class="mr-1">
          <h2>Mark all as read</h2>
        </div>
      </div>
      <div class="overflow-y-auto notifications pt-2">
        <div class="notification py-5 i-border-b-1 border-secondary-five relative" v-for="(notification, index) in notifications" :key="index" :class="{'bg-secondary-three': !notification.is_read}">
          <h2 class="fs-14 font-poppins fw-600 text-secondary-one i-ml-30 i-mr-30">{{ notification.notifiable ? notification.notifiable.title : '' }}</h2>
          <p class="break-words fs-14 font-poppins text-secondary-two fw-300 i-ml-30 i-mr-30">
            {{ notification.from.first_name }} {{ notification.notification }}
          </p>
          <h2 class="fs-14 font-poppins text-secondary-one mt-1 i-ml-30 i-mr-30">{{ $lodash.ago(notification.created_at) }}</h2>
          <button v-if="getLink(notification.type)"
                  @click="markAsRead(notification)"
                  class="focus-outline-none bg-secondary-two text-primary-three pl-4 pr-4 pt-2 pb-2 i-ml-30 i-mr-30 rounded mt-2 hover:bg-secondary-five fs-12 fw-400">Start Responding</button>
          <div class="absolute unread-box bg-primary-five" v-if="notification.is_read === 0"></div>
        </div>
        <div class="notification py-5 i-ml-30 i-mr-30 text-center" v-if="notifications.length <= 0">No notification found</div>
      </div>
      <div class="triangle-up"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Popup",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
      types: "notification/types",
    }),
  },
  methods: {
    getLink(type) {
      let notiType = this.types.find(nt => parseInt(type) === parseInt(nt.type));
      if(notiType) {
        return notiType.is_link;
      }
    },
    markAllAsRead() {
      this.$store.dispatch("notification/markAllAsRead");
    },
    markAsRead(notification) {
      notification.is_read = 1;
      this.$store.dispatch("notification/markAsRead", notification);
      this.$router.push(`/${notification.link}?from_notification=1`);
    }
  }
}
</script>

<style scoped>
.notification-popup {
  right: 3px;
  top: 30px;
  width: 450px;
  z-index: 9;
}
.notifications {
  max-height: 685px;
}
.unread-box {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 16px;
  right: 40px;
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f9f9f9;
  position: absolute;
  top: -10px;
  right: 10px;
}
</style>
